<template>
  <div class="map-detail">
    <div id="map" ref="map"></div>

    <footer-box
      v-if="validate"
      submitText="이 위치로 결정"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import FooterBox from '@/components/common/FooterBox'

export default {
  components: {
    FooterBox,
  },
  data() {
    return {
      mapContainer: null,
      mapOption: {},
      map: null,
      marker: null,
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return !isNaN(parseFloat(this?.postOffer?.geoX)) && !isNaN(parseFloat(this?.postOffer?.geoY))
      },
    },
  },
  mounted() {
    if (!this.validate) {
      this.$router.replace('/offer/post/6/map')
    }

    this.mapContainer = this.$refs.map // 지도를 표시할 div
    this.mapOption = {
      center: new daum.maps.LatLng(
        parseFloat(this.postOffer.geoX),
        parseFloat(this.postOffer.geoY)
      ), // 지도의 중심좌표
      level: 3, // 지도의 확대 레벨
      mapTypeId: daum.maps.MapTypeId.ROADMAP, // 지도종류
    }

    this.createMap()
  },
  methods: {
    nextStep() {
      this.$router.back()
    },
    createMap() {
      // 지도를 생성한다
      this.map = new daum.maps.Map(this.mapContainer, this.mapOption)
      this.createMarker(parseFloat(this.postOffer.geoX), parseFloat(this.postOffer.geoY))
      let geocoder = new daum.maps.services.Geocoder()
      let timer = null

      // 지도 중심 좌표 변화 이벤트를 등록한다
      daum.maps.event.addListener(this.map, 'center_changed', () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          let center = this.map.getCenter()
          let geoX = center.getLat()
          let geoY = center.getLng()

          // 좌표로 법정동 상세 주소 정보를 요청합니다
          geocoder.coord2Address(geoY, geoX, result => {
            if (result && result[0]) {
              let address = result[0]?.address?.address_name
              let location = '0'
              let locationKey = (result[0]?.address?.region_1depth_name || '전국').replace(/^(..).+/, '$1')
              for (let key in this.$config.constant.location) {
                if (this.$config.constant.location[key] === locationKey) {
                  location = key
                  break
                }
              }
              this.postOffer = {
                geoX,
                geoY,
                location,
                address,
              }

              this.marker.setMap(null)
              this.$nextTick().then(() => {
                this.createMarker(geoX, geoY)
              })
            }
          })
        }, 300)
      })
    },
    createMarker(geoX, geoY) {
      // 지도에 마커를 생성하고 표시한다
      this.marker = new daum.maps.Marker({
        position: new daum.maps.LatLng( geoX, geoY ), // 마커의 좌표
        map: this.map, // 마커를 표시할 지도 객체
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#page {
  .map-detail {
    // margin: 0 -3rem;
    padding: 0;
  }
  #map {
    width: 100vw;
    height: 100vh;
    height: calc(100vh - 19rem);
  }
}
</style>
